import { Filter } from '../../../types/filter.interface';
import AsyncCreateFilterView from './AsyncCreateFilterView';
import useAsyncCreateFilter from './useAsyncCreateFilter';

interface Props {
  readonly filters:Filter
  readonly setFilter:Function
  readonly fetcher:Function
  readonly name: string
  readonly options:any
  readonly count?:number
  readonly label:string
  readonly titleMode?:boolean
  readonly valueMapper?:(value: any) => any
}

export default function AsyncCreateFilter(props:Props){
  const useProps = useAsyncCreateFilter(props),
    passProps = {...useProps,count:props?.count,name:props.name,label:props.label,titleMode:props.titleMode};

  return <AsyncCreateFilterView {...passProps}/>;
}