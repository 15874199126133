import { useTranslation } from 'react-i18next';
import createFilterDescription from '../../../Utils/filter/createFilterDescription';
import _ from 'lodash';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import Select from 'react-select/dist/declarations/src/Select';
import {
  isDefaultOption, isExcludeOption, isContainOption, getOptionValue
} from '../../../Utils/filter/option';


interface props {
  readonly filters: any
  readonly setFilter: Function
  readonly fetcher: Function
  readonly name: string
  readonly label: String
  readonly options: any
  readonly valueMapper?: (value: any) => any
}

export default function useAsyncCreateFilter<T>({
  filters,
  setFilter,
  fetcher,
  name,
  label,
  options,
  valueMapper = (value: any) => value
}: props) {
  const {t} = useTranslation();

  function handleChange(select:Select) {
    const where: any[] = [],
      options = Array.isArray(select) ? select:[select];
    let valueNew: any[] = [];

    console.log('>> handleChange - select = ', select);

    //for "create"
    if ((options.filter((o) => isContainOption(o))).length > 0) {
      const reg = `/${_.map(
        options.filter((o) => isContainOption(o)),
        (o) => getOptionValue(o)
      ).join('|')}/i`;
      const inq = _.map(
        options.filter((o) => isContainOption(o)),
        (o) => getOptionValue(o)
      );

      where.push({[name]: { regexp: reg}});

      valueNew = [ ...valueNew, ...inq ];
    }

    //for exist options
    if ((options.filter((o) => isDefaultOption(o))).length > 0) {
      const inq = _.chain(options)
        .uniqBy('value')
        .filter((o) => isDefaultOption(o))
        .map((o) => getOptionValue(o))
        .value();

      where.push({[name]: { inq }});

      valueNew = [ ...valueNew, ...inq ];
    }

    //for exclude options
    if ((options.filter((o) => isExcludeOption(o))).length > 0) {
      const nin =  _.chain(options)
        .uniqBy('value')
        .filter((o) => isExcludeOption(o))
        .map((o) => getOptionValue(o))
        .value();
      where.push({[name]: { nin }});

      valueNew = [ ...valueNew, ...nin.map(n => `-${n}`) ];
    }

    setFilter(name, where.length === 0 ? undefined : {
      valueNew,
      whereClause: {or: [{and: where}]},//hacky....
      description: createFilterDescription(options),
      value:select,
      label
    });
  }

  async function promiseOptions(inputValue: string,callback: (options: T[]) => void) {
    const items = await fetcher(inputValue);
    callback(items);
    //return for click without change
    return items;
  }

  return {
    options: {
      menuPosition: 'absolute',
      defaultOptions: true,
      cacheOptions: true,
      styles:taglistStyle,
      createOptionPosition:'first',
      formatCreateLabel: (inputValue: string) =>
        `${t('contain')}: ${inputValue}`,
      loadOptions: promiseOptions,
      value: valueMapper(filters?.[name]?.value || []),
      onChange: handleChange,
      ...options,
    },
  };
}
