import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import Forgot from '../pages/Forgot/Forgot';
import Reset from '../pages/Reset/Reset';
import Tags from '../pages/Tags/Tags';
import NotFound from '../pages/404/NotFound';
import NewDomains from '../pages/Domains/NewDomains';
import Terms from '../pages/Terms/Terms';
import Advertisers from '../pages/Advertisers/Advertisers';
import TagSVG from '../components/Icon/svg/Tag.svg';
import DashboardSVG from '../components/Icon/svg/Dashboard.svg';
import NotificationsSVG from '../components/Icon/svg/Notifications.svg';
import TermSVG from '../components/Icon/svg/Term.svg';
import ReportingSVG from '../components/Icon/svg/Reporting.svg';
import ResumeSVG from '../components/Icon/svg/Resume.svg';
import SettingTerms from '../pages/Settings/Terms/SettingTerms';
import Brands from '../pages/Brands/Brands';
import CaptureTerm from '../pages/Terms/CaptureTerm/CaptureTerm';
import CompanyAccount from '../pages/CompanyAccount/CompanyAccount';
import PublicAds from '../pages/public/PublicAds/PublicAds';
import LegacyAdvertisers from '../pages/Legacy/LegacyAdvertisers';
import Users from '../pages/Users/Users';
import UserAdd from '../pages/User/UserAdd/UserAdd';
import UpdateUser from '../pages/User/UpdateUser/UpdateUser';
import Actions from '../pages/Actions/Actions';
import Todos from '../pages/Todos/Actions';
import Action from '../pages/Action/Action';

export const routes = [
  {
    path: '/404',
    title: 'Page not found',
    Page: NotFound,
  },
  {
    path: '/action/sem/data/summary/:filter',
    title: 'Content de vous revoir !',
    Page: LegacyAdvertisers,
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
  },
  {
    path: '/login',
    title: 'Content de vous revoir !',
    Page: Login,
    displayMenu: false,
    displayMenuMobile: false,
    guard: false,
  },
  {
    path: '/forgot',
    title: 'forgot password',
    Page: Forgot,
    displayMenu: false,
    displayMenuMobile: false,
    guard: false,
  },
  {
    path: '/logout',
    title: 'à bientôt !',
    Page: Logout,
    displayMenu: false,
    displayMenuMobile: false,
    guard: false,
  },
  {
    path: '/public-html-ad-content/:requestID',
    title: 'Monibrand Dashboard',
    Page: PublicAds,
    displayMenu: false,
    displayMenuMobile: false,
    guard: false,
  },
  {
    path: '/reset/:token',
    title: 'reset password',
    Page: Reset,
    displayMenu: false,
    displayMenuMobile: false,
    guard: false,
  },
  {
    path: '/advertisers',
    title: 'advertisers',
    testid: 'adv-link',
    Page: Advertisers,
    icon: NotificationsSVG,
    displayMenu: true,
    section: 'notifications',
    displayMenuMobile: true,
    guard: true,
    workspace: 'default',
  },
  {
    path: '/tags',
    title: 'tags',
    Page: Tags,
    testid: 'tag-link',
    icon: TagSVG,
    displayMenu: true,
    section: 'settings',
    displayMenuMobile: true,
    guard: true,
    workspace: 'default',
  },
  {
    path: '/terms/capture/:requestID',
    title: 'terms capture',
    section: 'monitoring',
    Page: CaptureTerm,
    guard: true,
    workspace: 'default',
  },
  //{
  //  path: '/terms',
  //  title: 'terms',
  //  Page: OldTerms,
  //  section: 'monitoring',
  //  icon: TermSVG,
  //  displayMenu: true,
  //  displayMenuMobile: true,
  //  guard: true,
  //},
  {
    path: '/terms',
    title: 'terms',
    Page: Terms,
    section: 'monitoring',
    icon: TermSVG,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    //flag: 'new_terms',
  },
  //{
  //  path: '/domains',
  //  title: 'domains',
  //  Page: Domains,
  //  icon: ReportingSVG,
  //  displayMenu: true,
  //  section: 'monitoring',
  //  displayMenuMobile: true,
  //  guard: true,
  //  workspace: 'default',
  //},
  {
    path: '/domains',
    title: 'domains',
    Page: NewDomains,
    icon: ReportingSVG,
    displayMenu: true,
    section: 'monitoring',
    displayMenuMobile: true,
    guard: true,
    workspace: 'default',
    //flag: 'new_terms',
  },
  {
    path: '/terms-management',
    title: 'term management',
    Page: SettingTerms,
    section: 'settings',
    icon: TermSVG,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    workspace: 'default',
  },
  {
    path: '/brands',
    title: 'brands',
    Page: Brands,
    section: 'settings',
    icon: TermSVG,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    isAdmin: true,
    workspace: 'default',
  },
  {
    path: '/company-account',
    title: 'company account',
    Page: CompanyAccount,
    section: 'settings',
    icon: TermSVG,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    isAdmin: true,
    workspace: 'default',
  },
  {
    path: '/users/create',
    title: 'user',
    Page: UserAdd,
    section: 'settings',
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
    isAdmin: true,
    workspace: 'default',
  },
  {
    path: '/users/:id',
    title: 'user',
    Page: UpdateUser,
    section: 'settings',
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
    isAdmin: true,
    workspace: 'default',
  },
  {
    path: '/users',
    title: 'users',
    Page: Users,
    section: 'settings',
    icon: TermSVG,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    isAdmin: true,
    workspace: 'default',
  },
  {
    path: '/action/:id',
    title: 'Action',
    section: 'home',
    icon: ResumeSVG,
    Page: Action,
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
    workspace: 'action',
    //flag: 'header_workspaces_switch1',
  },
  {
    path: '/actions',
    title: 'Actions',
    section: 'home',
    icon: ResumeSVG,
    Page: Actions,
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
    workspace: 'actions',
    flag: 'header_workspaces_switch',
  },
  {
    path: '/todo',
    title: 'To do',
    section: 'home',
    icon: ResumeSVG,
    Page: Todos,
    displayMenu: false,
    displayMenuMobile: false,
    guard: true,
    workspace: 'actions',
    flag: 'header_workspaces_switch',
  },
  {
    path: '/',
    title: 'Dashboard',
    section: 'home',
    icon: DashboardSVG,
    Page: Home,
    displayMenu: true,
    displayMenuMobile: true,
    guard: true,
    workspace: 'default',
  },
];
