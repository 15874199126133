import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../stores/page.store';
import _ from 'lodash';
import { getCookie } from '../../Utils/getSetCookie/getsetCookie';

export default class AdvertisersStore extends PageStore {
  statusCount: any[] = [];
  endpoint = '/Actions';
  selectedItems: any[] = [];
  order = 'priority';
  direction = 'desc';
  showAds = null;
  defaultFilters = {};

  constructor(user: any) {
    super(user);
    makeObservable(this, {
      selectedItems: observable,
      statusCount: observable,
      showAds: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      replaceAdvertiser: action,
      setAdvertiserGroup: action,
      setShowAds: action,
      updateItem: action,
      setStatusCount: action,
    });
    if (!this.filters) {
      this.filters = this.defaultFilters;
    }
  }

  setStatusCount(counts: any[]) {
    this.statusCount = counts;
  }

  async handleCount() {}

  async handleFetch() {}

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.tableHeader[0].value = 0;
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.id);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item: any) {
    this.selectedItems = _.xor([item.id], this.selectedItems);
  }

  get counters() {
    return [{ txt: 'action', count: this.count }];
  }

  setAdvertiserGroup(status: any) {
    this.selectedItems.forEach((id) => {
      const newItem = _.find(this.items, { id });
      newItem.status = status;
      this.replaceAdvertiser(newItem);
    });
  }

  replaceAdvertiser(advertiser: any) {
    const oldAdvertiser = _.find(this.items, { id: advertiser.id }),
      newAdvertiser = { ...advertiser };

    if (_.get(oldAdvertiser, 'dealer')) {
      newAdvertiser.dealer = oldAdvertiser.dealer;
    }

    this.items.splice(
      _.findIndex(this.items, { id: advertiser.id }),
      1,
      newAdvertiser
    );
  }

  updateItem(item: any, tags: any[]) {
    const index = _.findIndex(this.items, { termId: item.termId });
    if (index >= 0) {
      this.items.splice(index, 1, {
        ...item,
        categories: tags.map((o) => o.originalTag),
      });
    }
  }

  filterSearch(item: any) {
    const { engine, categories, brand, localization, canBeAccessBy, master } =
      item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (engine) {
      engine.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (master) {
      master.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (localization) {
      localization?.locale.toLowerCase().includes(this.inputFilterValue) &&
        count++;
    }

    if (brand) {
      if (brand.label) {
        brand?.label.toLowerCase().includes(this.inputFilterValue) && count++;
      }

      if (brand.name) {
        brand?.name.toLowerCase().includes(this.inputFilterValue) && count++;
      }
    }

    if (categories) {
      _.map(
        categories,
        (category) =>
          category.label.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    if (canBeAccessBy) {
      _.map(
        canBeAccessBy,
        (companyAccount) =>
          companyAccount.name.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    return count > 0 ? true : false;
  }

  setShowAds(value: any) {
    this.showAds = value;
  }

  get exportLink() {
    const queryString = new URLSearchParams({
      filter: JSON.stringify({
        where: this.computedFilters,
        include: [{ relation: 'dealer', scope: { include: 'categories' } }],
      }),
      csv: 'flatten',
      access_token: getCookie('access_token') as string,
    });
    const link =
      process.env.REACT_APP_MONIBRAND_BACKEND_API_URL +
      this.endpoint +
      '?' +
      queryString.toString();
    return link;
  }
}
