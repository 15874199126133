import { createContext } from 'react';
import AuthStore from './auth.store';
import UiStore from './ui.store';
import CacheStore from './cache.store';

export const StoreContext = createContext();
class RootStore {
  constructor(flagsmith) {
    this.authStore = new AuthStore(this, flagsmith);
    this.uiStore = new UiStore(this);
    this.cacheStore = new CacheStore(this);
  }
}

export default RootStore;
