import { action, computed, makeObservable, observable } from 'mobx';
import PageStore from '../../stores/page.store';
import dayjs from 'dayjs';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Backend from '../../stores/newBackend';

function buildCriteria(filters: any) {
  const criteria: { [key: string]: unknown } = Object.keys(filters)
    .filter((key) => !!filters[key])
    .map((key) => ({ key, value: filters[key].valueNew }))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val.key]: val.value,
      }),
      {}
    );

  if (typeof criteria.device === 'string') {
    criteria.device = criteria.device.split(',');
  }

  if (typeof criteria.type === 'string') {
    criteria.type = criteria.type.split(',');
  }

  if (typeof criteria.keywordType === 'string') {
    criteria.keywordType = criteria.keywordType.split(',');
  }

  if (typeof criteria.region === 'string') {
    criteria.region = criteria.region.split(',');
  }

  return criteria;
}

export default class TermsStore extends PageStore {
  statusCount = [];
  totalViews = 0;
  filters: any = {};
  adsCount = 0;
  endpoint = '/Sems/groupTerms';
  selectedItems: any[] = [];
  selectedItem = null;
  tableHeader = [
    {
      name: 'selectAll',
      value: 0,
      handleClick: () => this.toggleSelectAll(),
      center: true,
    },
    { name: 'content', title: 'terms', sortable: true, center: true },
    { name: 'tags', title: 'tags', center: true },
    { name: 'engine', title: 'engine', sortable: true, center: true },
    { name: 'locale', title: 'country', sortable: true, center: true },
    {
      name: 'nbCompetitors',
      title: 'competitors count',
      sortable: true,
      center: true,
    },
    { name: 'nbAds', title: 'ads counts', sortable: true, center: true },
    { name: 'nbViews', title: 'ads views', sortable: true, center: true },
  ];
  order = 'nbCompetitors';
  direction = 'desc';
  getDefaultDate = () => {
    const start = dayjs().subtract(7, 'days').startOf('day').toDate();
    const end = dayjs().endOf('day').toDate();
    return {
      label: 'date',
      whereClause: {
        between: [start, end],
      },
      valueNew: { start, end },
      description: i18next.t('last 7 days'),
    };
  };
  token = '';
  hasNextPage = false;
  hasPreviousPage = false;
  nextLink: string | null = null;
  previousLink: string | null = null;
  exportCSVLink: string = '';
  isAdmin: boolean = false;

  constructor(userID: string, token: string, isAdmin: boolean) {
    super(userID);

    makeObservable(this, {
      statusCount: observable,
      totalViews: observable,
      adsCount: observable,
      selectedItems: observable,
      selectedItem: observable,
      commonTagsForSelectItems: computed,
      setSelectedItems: action,
      setAdsCount: action,
      setTotalViews: action,
      toggleSelectOne: action,
      toggleSelectAll: action,
      updateItem: action,
      hasNextPage: observable,
      hasPreviousPage: observable,
      exportCSVLink: observable,
    });

    this.isAdmin = isAdmin;
    this.token = token;
  }

  async handleCount() {}

  updateItem(item: any, tags: any) {
    const index = _.findIndex(this.items, { id: item.id });
    if (index >= 0) {
      this.items.splice(index, 1, {
        ...item,
        categories: tags.map((o: any) => o.originalTag),
      });
    }
  }

  setSelectedItems(selectedItems: any) {
    this.selectedItems = selectedItems;
  }

  setAdsCount(count: number) {
    this.adsCount = count;
  }

  setTotalViews(count: number) {
    this.totalViews = count;
  }

  async handleNext() {
    if (!this.nextLink) {
      return;
    }
    this.page += 1;
    this.handleFetch(this.nextLink);
  }

  async handlePrevious() {
    if (!this.previousLink) {
      return;
    }
    this.page -= 1;
    this.handleFetch(this.previousLink);
  }

  async handleFetch(link?: string) {
    this.setIsLoading(true);
    this.setIsLoadingCount(true);
    this.setCount(0);
    this.setTotalViews(0);
    this.setAdsCount(0);
    this.setErrorOther(null);
    this.setErrorTimeout(null);

    this.hasNextPage = false;
    this.nextLink = null;
    this.hasPreviousPage = false;
    this.previousLink = null;
    this.exportCSVLink = '';

    const defaultCriteria = {
      page: {
        size: this.perPage,
      },
    };

    if (!this.filters.date) {
      this.filters.date = this.getDefaultDate();
    }

    const filterKeys: string[] = [];
    Object.keys(this.filters).forEach((key) => {
      if (this.filters[key]) {
        filterKeys.push(key);
      }
    });

    const shouldforceBrandID =
      process.env.NODE_ENV === 'production' &&
      !this.filters.brandID &&
      filterKeys.length === 1 &&
      this.isAdmin;

    // @TODO HACKYYY
    if (shouldforceBrandID) {
      this.filters.brandID = {
        description: 'Monibrand',
        label: 'brand',
        valueNew: ['61704aacb03f8e1aa6fc5a72'],
        value: [{ label: 'Monibrand', value: '61704aacb03f8e1aa6fc5a72' }],
        whereClause: {
          or: [
            {
              and: [
                {
                  brandID: {
                    inq: ['61704aacb03f8e1aa6fc5a72'],
                  },
                },
              ],
            },
          ],
        },
      };
    }

    const criteria = {
      ...defaultCriteria,
      ...buildCriteria(this.filters),
      sort: {
        by: this.order,
        direction: this.direction,
      },
    };

    try {
      const result = await Backend.loadKeywordStat(this.token, criteria, link);
      this.setCount(result.meta.page.total);

      if (
        result.meta.page.totalViews !== undefined &&
        Number.isInteger(result.meta.page.totalViews)
      ) {
        this.setTotalViews(result.meta.page.totalViews);
      }

      if (
        result.meta.page.totalAds !== undefined &&
        Number.isInteger(result.meta.page.totalAds)
      ) {
        this.setAdsCount(result.meta.page.totalAds);
      }

      this.setItems(result.data);

      this.exportCSVLink = `${process.env.REACT_APP_MONIBRAND_BACKEND_V2_API_URL}${result.links.exportCSV}&access_token=${this.token}`;

      if (result.links.next) {
        this.hasNextPage = true;
        this.nextLink = result.links.next;
      }

      if (result.links.prev) {
        this.hasPreviousPage = true;
        this.previousLink = result.links.prev;
      }
    } catch (e: any) {
      if (e.status === 408) {
        this.setErrorTimeout(true);
      } else {
        this.setErrorOther(true);
      }
      toast.error(i18next.t('The research was not successful') as string);
    }

    this.setIsLoading(false);
    this.setIsLoadingCount(false);
    this.setSelectedItems([]);
  }

  get counters() {
    return [
      { txt: 'term', count: this.count },
      { txt: 'ads', count: this.adsCount },
      { txt: 'views', count: this.totalViews },
    ];
  }

  filterSearch(item: any) {
    if (this.inputFilterValue === '') {
      return true;
    }

    const { engine, content } = item;
    let count = 0;

    if (content) {
      content.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (engine) {
      engine.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    //if (categories && categories.length > 0) {
    //  _.map(
    //    categories,
    //    (category) =>
    //      category.label.toLowerCase().includes(this.inputFilterValue) &&
    //      count++
    //  );
    //}

    return count > 0 ? true : false;
  }

  get commonTagsForSelectItems() {
    /**recupère les tags des items selectionnés */

    const tags = _.chain(this.items)
      .filter((o) => !!this.selectedItems.find((it) => it === o.id))
      .reduce((acc, { categories }) => {
        return [...acc, ...categories];
      }, [] as any[])
      .value();

    /** garde uniquement les tags en commun à tous les items selectionnés */
    const commonTags = _.chain(tags)
      .reduce((acc, categorie) => {
        //Le nombre de tags identique doit être égal au nombre de terms selectionnés.
        const isCommon = tags.filter((o) => o.id === categorie.id);
        if (isCommon.length === this.selectedItems.length) {
          acc.push(categorie);
        }

        return acc;
      }, [] as any[])
      .uniqBy('id')
      .value();

    return commonTags;
  }

  toggleSelectOne(item: any) {
    this.setSelectedItems(_.xor([item.id], this.selectedItems));
  }

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.setSelectedItems([]);
      this.tableHeader[0].value = 0;
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.id);
      this.tableHeader[0].value = 1;
    }
  }

  get exportLink() {
    return this.exportCSVLink;
  }
}
