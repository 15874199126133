import { useEffect, useState, useContext } from 'react';
import classes from './ActionAdvertisementPanels.module.css';
import ActionPanel from './ActionPanel';
import { useTranslation } from 'react-i18next';
import useGetAdvertisements from '../../components/Hooks/data/getAdvertisements';
import useGetAdvertisementsByActionID from '../../components/Hooks/data/getAdvertisementsByActionID';
import { StoreContext } from '../../stores';
import ActionAdvertisementList from './ActionAdvertisementList';
import { Action } from '../../types/action.newBackend';
import LoaderSmall from '../../components/_ui/LoaderSmall/LoaderSmall';

const CLASSICAL_ADS = 'ads';
const SHOPPING_ADS = 'shopping';

const AdvertisementPanelLoader = ({ title }: { title: string }) => {
  return (
    <div className={classes.loader}>
      <div>{title}</div>
      <div className={classes.loaderParenthesisStart}>(</div>
      <div>
        <LoaderSmall color='white' />
      </div>
      <div className={classes.loaderParenthesisEnd}>)</div>
    </div>
  );
};

interface Props {
  action: Action;
}

export default function ActionAdvertisementPanels({ action }: Props) {
  const { authStore } = useContext(StoreContext),
    { t } = useTranslation(),
    [openedClassical, setOpenedClassical] = useState(false),
    [openedShopping, setOpenedShopping] = useState(false),
    [openedPanel, setOpenedPanel] = useState('');

  const advertisementData = useGetAdvertisements();
  const advertisementDataByActionID = useGetAdvertisementsByActionID();

  const {
    loading,
    advertisements,
    fetch,
    fetchCount,
    clear,
  } = advertisementData;

  const loadingByActionID            = advertisementDataByActionID.loading;
  const loadingCountByActionID       = advertisementDataByActionID.loadingCount;
  const advertisementsByActionID     = advertisementDataByActionID.advertisements;
  const countAdvertisementByActionID = advertisementDataByActionID.countAdvertisement;
  const fetchByActionID              = advertisementDataByActionID.fetch;
  const fetchCountByActionID         = advertisementDataByActionID.fetchCount;
  const clearByActionID              = advertisementDataByActionID.clear;

  const { brand } = action;

  useEffect(() => {
    if (openedPanel) {
      const criteria = {
        type: openedPanel,
        brandId: action.brandId,
        domain: action.domain,
        fromDate: action.createdDate,
        engine: action.engine,
        country: action.country,
        limit: 5,
      };
      const actionCriteria = {
        type: openedPanel,
        limit: 5,
      };
      fetch(authStore.token, criteria);
      fetchByActionID(authStore.token, action.ID, actionCriteria);
    } else {
      clear();
      clearByActionID();
    }
  }, [openedPanel]); // eslint-disable-line

  useEffect(() => {
    const criteria = {
      type: openedPanel,
      brandId: action.brandId,
      domain: action.domain,
      fromDate: action.createdDate,
      engine: action.engine,
      country: action.country,
      limit: 5,
    };
    fetchCount(authStore.token, criteria);
    fetchCountByActionID(authStore.token, action.ID);
  }, []); // eslint-disable-line

  const onTogglePanel = (panelId: string) => {
    if (panelId === CLASSICAL_ADS && openedPanel === panelId) {
      setOpenedClassical(false);
      setOpenedShopping(false);
    }
    if (panelId === CLASSICAL_ADS && openedPanel !== panelId) {
      setOpenedClassical(true);
      setOpenedShopping(false);
    }
    if (panelId === SHOPPING_ADS && openedPanel === panelId) {
      setOpenedShopping(false);
      setOpenedClassical(false);
    }
    if (panelId === SHOPPING_ADS && openedPanel !== panelId) {
      setOpenedShopping(true);
      setOpenedClassical(false);
    }
    setOpenedPanel((openedPanel: string) => {
      return openedPanel === panelId ? '' : panelId;
    });
  };

  return (
    <>
      <div
        className={`${classes.container} ${
          !brand.createShoppingNotification ||
          (!loadingCountByActionID && countAdvertisementByActionID?.countShopping === 0) ||
          (!loadingCountByActionID && countAdvertisementByActionID?.countAdvertisement === 0)
            ? classes.noShoppingAdvertisement
            : ''
        }`}
      >
        {!loadingCountByActionID && countAdvertisementByActionID?.countAdvertisement > 0 && (
          <ActionPanel
            title={
              <div>
                {t('Classical advertisement byActionID')} (
                {countAdvertisementByActionID && countAdvertisementByActionID?.countAdvertisement
                  ? countAdvertisementByActionID?.countAdvertisement
                  : ''}
                )
              </div>
            }
            className={classes.classicalAdvertisement}
            headerClassName={classes.classicalAdvertisementHeader}
            openToggleClassName={classes.classicalAdvertisementOpenToggle}
            noBody
            forcedOpened={openedClassical}
            opened={openedPanel === CLASSICAL_ADS}
            disabled={openedPanel === SHOPPING_ADS}
            clickOnPanel
            onToggleOpen={() => onTogglePanel(CLASSICAL_ADS)}
          />
        )}
        {loadingCountByActionID && (
          <ActionPanel
            title={
              <AdvertisementPanelLoader title={t('Classical advertisement byActionID')} />
            }
            className={classes.classicalAdvertisement}
            headerClassName={classes.classicalAdvertisementHeader}
            openToggleClassName={classes.classicalAdvertisementOpenToggle}
            noBody
            opened={false}
            disabled={true}
            clickOnPanel={false}
            onToggleOpen={() => null}
          />
        )}
        {brand.createShoppingNotification &&
          !loadingCountByActionID &&
          countAdvertisementByActionID?.countShopping > 0 && (
          <ActionPanel
            title={
              <div>
                {t('Shopping advertisement byActionID')} (
                {countAdvertisementByActionID && countAdvertisementByActionID?.countShopping
                  ? countAdvertisementByActionID?.countShopping
                  : ''}
                )
              </div>
            }
            className={classes.shoppingAdvertisement}
            headerClassName={classes.shoppingAdvertisementHeader}
            openToggleClassName={classes.shoppingAdvertisementOpenToggle}
            noBody
            opened={openedPanel === SHOPPING_ADS}
            forcedOpened={openedShopping}
            disabled={openedPanel === CLASSICAL_ADS}
            clickOnPanel
            onToggleOpen={() => onTogglePanel(SHOPPING_ADS)}
          />
        )}
        {brand.createShoppingNotification && loadingCountByActionID && (
          <ActionPanel
            title={
              <AdvertisementPanelLoader title={t('Shopping advertisement byActionID')} />
            }
            className={classes.shoppingAdvertisement}
            headerClassName={classes.shoppingAdvertisementHeader}
            openToggleClassName={classes.shoppingAdvertisementOpenToggle}
            noBody
            opened={false}
            disabled={true}
            clickOnPanel={false}
            onToggleOpen={() => null}
          />
        )}
      </div>
      {openedPanel && (
        <div className={classes.advertisementContainer}>
          <ActionAdvertisementList
            advertisements={advertisementsByActionID}
            loading={loadingByActionID}
            shoppingType={openedPanel === SHOPPING_ADS ? true : false}
          />
        </div>
      )}
    </>
  );
}
